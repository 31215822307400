import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const Unauthorized = () => {
    var mbfs_logo = require('../src/Header/Images/mbfs_logo_mob.png');
    const location = useLocation();
    const navigate = useNavigate();
    const Logout = () => {
        navigate('/')
    }
    const [id, setId] = useState(null);
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const queryId = searchParams.get('id');
        console.log(queryId)
        setId(queryId);
    }, [location.search]);

    return (
        <div>
            <div className="top_model">
                <div className="model_cent">
                    <img className='Logo_img' src={mbfs_logo} alt="" />
                    <h2>Unauthorized</h2>
                    <p>Your administrator has not assigned you appropriate rights to this page, feature or function.</p>
                    {id && id === 'Unauthorized' ? (
                        ''
                    ) : (
                        <button
                            onClick={Logout}
                            style={{ fontSize: '14px' }}
                            className="grd_btns"
                        >
                            Login
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Unauthorized